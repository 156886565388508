<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- Add an existing domain (requires verification) -->
                    <v-card tile elevation="4" class="pa-0 mt-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Verify a registered domain</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p>You will need to verify ownership of the domain before it is added to your account.</p>
                            <p>To get started, enter the domain name below.</p>
                        </v-card-text>
                        <v-card-text class="px-5 pt-0">
                        <v-form @submit.prevent="onSubmitForm" @keyup.enter.native.prevent="onSubmitForm">
                            <v-text-field
                                ref="domainNameInput"
                                v-model=newDomainName
                                label="Domain Name"
                                :rules="newDomainNameRules"
                                validate-on-blur
                                color="green"
                                required
                                hint="The name for the domain"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isAddFormComplete">
                                <span>Continue</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';

export default {
    data: () => ({
        account: null,
        domain: null,
        domainVerification: null,
        error: null,
        newDomainName: null,
        newDomainNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Domain name is required',
        ],
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isAddFormComplete() {
            // TODO: check that domain name is not too similar to existing domain name
            return typeof this.newDomainName === 'string' && this.newDomainName.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async onSubmitForm() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { onSubmitForm: true }); // one overall loading indicator so it's a smooth transition, without fits and starts between each of the network requests below
                this.domain = await this.searchDomain(this.newDomainName);
                console.log(`domain search results: ${JSON.stringify(this.domain)}`);
                if (this.domain === null) {
                    this.domain = await this.createDomain(this.newDomainName);
                    if (this.domain === null) {
                        this.error = true;
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot start domain verification' });
                        return;
                    }
                    this.domainVerification = await this.createDomainVerification(this.domain.id);
                    this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domainId: this.domain.id } });
                    return;
                    // TODO: or include the card on this page? <DomainVerificationCard :domain="domain" :verification="domainVerification" @verified="onDomainVerificationComplete"/>
                }
                if (this.domain.status !== 'verified') {
                    this.domainVerification = await this.searchDomainVerification(this.domain.id);
                    if (!this.domainVerification) {
                        this.domainVerification = await this.createDomainVerification(this.domain.id);
                    }
                    this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domainId: this.domain.id } });
                    return;
                    // TODO: or include the card on this page? <DomainVerificationCard :domain="domain" :verification="domainVerification" @verified="onDomainVerificationComplete"/>
                }
                this.$router.push({ name: 'account-edit-domain', params: { accountId: this.$route.params.accountId, domainId: this.domain.id } });
            } catch (err) {
                console.error('failed to verify domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { onSubmitForm: false });
            }
        },
        async searchDomain(name) {
            try {
                this.$store.commit('loading', { searchDomain: true });
                const result = await this.$client.account(this.$route.params.accountId).domain.search({ name });
                if (result.list.length === 0) {
                    return null;
                }
                return result.list[0];
            } catch (err) {
                console.error('failed to search domain', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { searchDomain: false });
            }
        },
        async searchDomainVerification(domainId) {
            try {
                this.$store.commit('loading', { searchDomainVerification: true });
                const result = await this.$client.account(this.$route.params.accountId).domainVerification.search({ domain_id: domainId });
                if (result.list.length === 0) {
                    return null;
                }
                return result.list[0];
            } catch (err) {
                console.error('failed to search domain verification', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { searchDomainVerification: false });
            }
        },
        async createDomainVerification(domainId) {
            try {
                this.$store.commit('loading', { createDomainVerification: true });
                const domainVerification = await this.$client.account(this.$route.params.accountId).domainVerification.create({ domain_id: domainId });
                console.log(`createDomainVerification: ${JSON.stringify(domainVerification)}`); // { domain_id, code, not_after }
                return domainVerification;
            } catch (err) {
                console.error('failed to start domain verification', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { createDomainVerification: false });
            }
        },
        async createDomain(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const response = await this.$client.account(this.$route.params.accountId).domain.create({ name });
                console.log('createDomain response: %o', response);
                const { isCreated, id, error } = response;
                if (!isCreated && error) {
                    switch (error) {
                    /*
                    // TODO: this error will never happen now when creating a domain, because we allow domain record to be created in ACCOUNT schema, and THEN we start verification, so we will just show the "verify this domain" action to user when they're looking at it in the list or in edit view; so it just won't happen on this view.
                    case 'DOMAIN_VERIFICATION_REQUIRED':
                        this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                        break;
                    */
                    /*
                    // TODO: this error will never happen now when creating a domain, it will only happen after verification is completed successfully and the backend tries to assign the domain to the account, but it's already assigned to another account; so it just won't happen on this view.
                    case 'DOMAIN_ALREADY_CLAIMED':
                        this.$router.push({ name: 'account-dispute-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                        break;
                    */
                    case 'UNKNOWN':
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain', message: 'Unknown error' });
                        break;
                    }
                }
                if (isCreated && id) {
                    return { id, name: this.newDomainName, status: 'new' };
                }
                return null;
            } catch (err) {
                if (err.response) {
                    console.log(`failed to create domain, status is ${err.response.status}`);
                    /*
                    if (err.response?.status === 409) {
                        this.verifyDomain();
                        return;
                    }
                    */
                }
                console.error('failed to create domain', err);
                this.error = true;
                return null;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
        /*
        async verifyDomain() {
            try {
                this.$store.commit('loading', { verifyDomain: true });
                const domainList = await this.$client.account(this.$route.params.accountId).domain.search({ name: this.newDomainName });
                if (domainList.length === 0) {
                    console.log('domain not found');
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot verify domain' });
                    return;
                }
                const domain = domainList[0];
                // domain already exists in account, redirect user to domain verification page
                this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domainId: domain.id } });
            } catch (err) {
                console.error('failed to verify domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { verifyDomain: false });
            }
        },
        */
        cancel() {
            this.$router.go(-1);
        },
    },
    mounted() {
        this.loadAccount();
        setTimeout(() => { this.$nextTick(() => this.$refs.domainNameInput.focus()); }, 1);
    },
};
</script>
